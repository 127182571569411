import { Enumeration } from '@bp/shared/models/core/enum';

export class SubscriptionPlanType extends Enumeration {

	static free = new SubscriptionPlanType();

	static startup = new SubscriptionPlanType();

	static pro = new SubscriptionPlanType();

	static growth = new SubscriptionPlanType();

	static enterprise = new SubscriptionPlanType();

}
