import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { uniq } from 'lodash-es';

import { Injectable } from '@angular/core';

import { ZoneService } from '@bp/shared/rxjs';
import { isPresent } from '@bp/shared/utilities';
import { StorageService } from '@bp/shared/services';

import { IGoogleOptimizeExperiments, UiExperiment } from '../models';

const experimentQueryParamName = 'experiment';
const appInitUrl = new URL(window.location.toString());

@Injectable({ providedIn: 'root' })
export class UiExperimentsService {

	private readonly _experiments$ = new BehaviorSubject<UiExperiment[]>([]);

	readonly experiments$ = this._experiments$.asObservable();

	get experiments(): UiExperiment[] {
		return this._experiments$.value;
	}

	showPhoneInputInsteadIndustryInput$ = this._isUiExperimentOn$(UiExperiment.showPhoneInputInsteadIndustryInput);

	showLeadPhoneInput$ = this._isUiExperimentOn$(UiExperiment.showLeadPhoneInput);

	private get _googleOptimizeExperiments(): IGoogleOptimizeExperiments | undefined {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		return (<any>window).bpGoogleOptimizeExperiments;
	}

	constructor(
		private readonly _zoneService: ZoneService,
		private readonly _storageService: StorageService,
	) {
		this._trySetQueryParamBasedExperiment();

		this._observeGoogleOptimizeExperiments();

		this._updateExperimentsBasedOnGoogleOptimizeState();

	}

	getExperimentsDisplayNames(): string[] {
		return this.experiments.map(experiment => experiment.displayName);
	}

	private _updateExperimentsBasedOnGoogleOptimizeState(): void {
		if (!this._googleOptimizeExperiments)
			return;

		this._addExperiments(
			this._googleOptimizeExperiments.experiments
				.map(experiment => UiExperiment.parse(experiment))
				.filter(isPresent),
		);
	}

	private _observeGoogleOptimizeExperiments(): void {
		this._googleOptimizeExperiments?.registerOnChange(
			() => void this._zoneService.runInAngularZone(
				() => void this._updateExperimentsBasedOnGoogleOptimizeState(),
			),
		);
	}

	private _isUiExperimentOn$(experiment: UiExperiment): Observable<boolean> {
		return this._experiments$
			.pipe(
				map(experiments => experiments.includes(experiment)),
				distinctUntilChanged(),
			);
	}

	private _trySetQueryParamBasedExperiment(): void {
		const experimentFromQueryParams = this._getQueryParamBasedExperiment();

		if (experimentFromQueryParams)
			this._addExperiments([ experimentFromQueryParams ]);
	}

	private _getQueryParamBasedExperiment(): UiExperiment | null {
		const experiment = UiExperiment.parse(
			appInitUrl.searchParams.get(experimentQueryParamName),
		);

		if (experiment)
			this._storageService.set(experimentQueryParamName, experiment.name);

		return UiExperiment.parse(this._storageService.get(experimentQueryParamName));
	}

	private _addExperiments(experiments: UiExperiment[]): void {
		this._experiments$.next(uniq([
			...this._experiments$.value,
			...experiments,
		]));
	}

}
