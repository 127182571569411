import { Observable, startWith, Subject, switchMap } from 'rxjs';

import { Directive, EmbeddedViewRef, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';

import { MediaService } from '../services';
import { MediaBreakpointDeviceLiteral } from '../models';

@Directive()
export abstract class IfConditionStructuralBaseDirective extends Destroyable implements OnInit {
	private readonly _checkMediaBreakpointCondition$ = new Subject<void>();

	private _targetMediaBreakpointDeviceLiteral: MediaBreakpointDeviceLiteral = null!;

	private _elseTemplateRef: TemplateRef<any> | null = null;

	private _thenViewRef: EmbeddedViewRef<any> | null = null;

	private _elseViewRef: EmbeddedViewRef<any> | null = null;

	constructor(
		protected _mediaService: MediaService,
		private readonly _viewContainer: ViewContainerRef,
		private _thenTemplateRef: TemplateRef<any> | null,
	) {
		super();
	}

	ngOnInit(): void {
		this._onInputsOrMediaChangeUpdateView();
	}

	private _onInputsOrMediaChangeUpdateView(): void {
		this._checkMediaBreakpointCondition$
			.pipe(
				startWith(null),
				switchMap(() => this.onMediaBreakpointConditionChange$(this._targetMediaBreakpointDeviceLiteral)),
				takeUntilDestroyed(this),
			)
			.subscribe(isMediaConditionMet => void this._updateViewByCondition(isMediaConditionMet));
	}

	abstract onMediaBreakpointConditionChange$(mediaBreakpointDeviceLiteral: MediaBreakpointDeviceLiteral): Observable<boolean>;

	protected _setMediaBreakpointDeviceLiteral(mediaBreakpointDeviceLiteral: MediaBreakpointDeviceLiteral): void {
		this._targetMediaBreakpointDeviceLiteral = mediaBreakpointDeviceLiteral;

		this._checkMediaBreakpointCondition();
	}

	protected _setThenTemplate(templateRef: TemplateRef<any> | null): void {
		this._thenTemplateRef = templateRef;

		this._thenViewRef = null; // clear previous view if any.

		this._checkMediaBreakpointCondition();
	}

	protected _setElseTemplate(templateRef: TemplateRef<any> | null): void {
		this._elseTemplateRef = templateRef;

		this._elseViewRef = null; // clear previous view if any.

		this._checkMediaBreakpointCondition();
	}

	private _checkMediaBreakpointCondition(): void {
		this._checkMediaBreakpointCondition$.next();
	}

	private _updateViewByCondition(isMediaCondition: boolean): void {
		if (isMediaCondition)
			this._updateThenView();
		else
			this._updateElseView();
	}

	private _updateThenView(): void {
		if (this._thenViewRef)
			return;

		this._elseViewRef = null;

		this._viewContainer.clear();

		if (!this._thenTemplateRef)
			return;

		this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef);

		this._thenViewRef.markForCheck();
	}

	private _updateElseView(): void {
		if (this._elseViewRef)
			return;

		this._thenViewRef = null;

		this._viewContainer.clear();

		if (!this._elseTemplateRef)
			return;

		this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef);

		this._elseViewRef.markForCheck();
	}
}
