<button
	mat-button
	class="primary"
	color="primary"
	[attr.type]="type"
	[ngClass]="btnClass"
	[class.pending]="inProgress"
	[disabled]="disabled || inProgress"
>
	<mat-progress-bar
		*ngIf="progressType === 'top-bar' && inProgress"
		@fade
		class="progress-bar-top-sticked"
		[value]="progress || 0"
		[mode]="isNumber(progress) ? 'determinate' : 'indeterminate'"
	>
	</mat-progress-bar>

	<ng-content></ng-content>
</button>

<mat-progress-spinner
	*ngIf="progressType === 'spinner' && inProgress"
	@fade
	[value]="progress || 0"
	[mode]="isNumber(progress) ? 'determinate' : 'indeterminate'"
>
</mat-progress-spinner>
