import { MetadataEntity, Mapper, numberMapper, Default, DTO } from '@bp/shared/models/metadata';

import { PricePerCurrencyMap, pricePerCurrencyMapper } from './mappers';

export class SubscriptionPlanTransactionsAmountTier extends MetadataEntity {

	/**
	 * if null there is no upper boundary, can be only the last item in the array
	 */
	@Mapper(numberMapper)
	@Default(null)
	to!: number | null;

	/**
	 * Price per transaction in the specified range of transactions
	 * if null the tire_price might be used instead
	 */
	@Mapper(pricePerCurrencyMapper)
	@Default(null)
	pricePerTransaction!: PricePerCurrencyMap | null;

	/**
	 * Price for the whole range of transactions, if the merchant's transactions counter in the range, the price is charged
	 * If null the price_per_transaction might be used instead
	 */

	@Mapper(pricePerCurrencyMapper)
	@Default(null)
	tierPrice!: PricePerCurrencyMap | null;

	constructor(dto?: DTO<SubscriptionPlanTransactionsAmountTier>) {
		super(dto);
	}

}
