import { Enumeration } from '@bp/shared/models/core/enum';

export class SubscriptionPlanChargePeriod extends Enumeration {

	static monthly = new SubscriptionPlanChargePeriod(undefined, 1);

	static annually = new SubscriptionPlanChargePeriod('Yearly', 12);

	constructor(
		displayName: string | undefined,
		public readonly monthsInPeriod: number,
	) {
		super(displayName);
	}

}
