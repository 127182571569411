import { lastValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';

import type { Dictionary } from '@bp/shared/typings';
import { $, applySnakeCaseToKeysRecursively } from '@bp/shared/utilities';
import { AsyncVoidSubject } from '@bp/shared/rxjs';

import { EnvironmentService } from '../environment.service';
import { RouterService } from '../router.service';
import { TelemetryService } from '../telemetry';

import type { IIntercom, IIntercomBootOptions, IIntercomCompany, IIntercomOptions } from './models';

// eslint-disable-next-line vars-on-top, @typescript-eslint/naming-convention
declare let Intercom: IIntercom;

@Injectable({
	providedIn: 'root',
})
export class IntercomService {

	enabled = !!this._environmentService.intercom;

	private readonly _isIntercomReady$ = new AsyncVoidSubject();

	private _inited = false;

	private readonly _windowPropertyServiceName = 'BP_IntercomService';

	constructor(
		private readonly _environmentService: EnvironmentService,
		private readonly _telemetryService: TelemetryService,
		private readonly _router: RouterService,
	) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		(<any> window)[this._windowPropertyServiceName] = this;

		this._trackUserSessionRecordingUrl();
	 }

	init(): void {
		if (this._inited || !this.enabled)
			return;

		TelemetryService.log('Init Intercom');

		this._injectIntercomScript();

		this._bootIntercom({ platform: this._environmentService.platform });

		this._whenPageChangeUpdateIntercom();

		this._inited = true;
	}

	async update(options: IIntercomOptions = {}): Promise<void> {
		if (!this.enabled)
			return;

		await this._waitForIntercom();

		options = this._addUserSessionRecordingsURLIfPossible(options);

		Intercom('update', applySnakeCaseToKeysRecursively(options));
	}

	async company(company: IIntercomCompany): Promise<void> {
		if (!this.enabled)
			return;

		await this._waitForIntercom();

		void this.update({ company });
	}

	async show(): Promise<void> {
		if (!this.enabled)
			return;

		await this._waitForIntercom();

		Intercom('show');
	}

	async trackEvent(event: string, data?: Dictionary<string>): Promise<void> {
		if (!this.enabled)
			return;

		await this._waitForIntercom();

		Intercom('trackEvent', event, data ? applySnakeCaseToKeysRecursively(data) : undefined);
	}

	async reboot(): Promise<void> {
		if (!this.enabled)
			return;

		await this.shutdown();

		this._bootIntercom();
	}

	async shutdown(): Promise<void> {
		if (!this.enabled)
			return;

		await this._waitForIntercom();

		Intercom('shutdown');
	}

	async getUserId(): Promise<string | null> {
		if (!this.enabled)
			return Promise.resolve(null);

		await this._waitForIntercom();

		return Intercom('getVisitorId') ?? null;
	}

	private async _waitForIntercom(): Promise<void> {
		await lastValueFrom(this._isIntercomReady$);
	}

	// @ts-expect-error called inside the intercom script snippet
	private _intercomIsReady(): void {
		this._isIntercomReady$.complete();
	}

	private _bootIntercom(options: IIntercomBootOptions = {}): void {
		Intercom('boot', applySnakeCaseToKeysRecursively({
			appId: this._environmentService.intercom,
			...options,
		}));
	}

	private _whenPageChangeUpdateIntercom(): void {
		this._router.navigationEnd$.subscribe(() => void this.update());
	}

	private _trackUserSessionRecordingUrl(): void {
		this._telemetryService.userSessionRecordingUrl$?.subscribe(url => void this.trackEvent('LogRocket', {
			userSessionRecordingUrl: url,
		}));
	}

	private _addUserSessionRecordingsURLIfPossible(options: IIntercomOptions): IIntercomOptions {
		if (!this._environmentService.logrocket || !options.email)
			return options;

		return {
			...options,
			recordings: `https://app.logrocket.com/${ this._environmentService.logrocket }/sessions?u=${ options.email }`,
		};
	}

	private _injectIntercomScript(): void {
		document.body.append($.buildAsyncScriptElement({
			code: /* javascript*/ `(function(){
				var w=window;
				var ic=w.Intercom;
				if (typeof ic==="function") {
					ic('reattach_activator');
					ic('update',w.intercomSettings);
				} else {
					var d=document;
					var i=function() {
						i.c(arguments);
					};
					i.q=[];
					i.c=function(args) {
						i.q.push(args);
					};
					w.Intercom=i;
					var l=function() {
						var s=d.createElement('script');
						s.type='text/javascript';
						s.async=true;
						s.src='https://widget.intercom.io/widget/${ this._environmentService.intercom }';
						s.onload = function () {
							window['${ this._windowPropertyServiceName }']._intercomIsReady();
						}
						var x=d.getElementsByTagName('script')[0];
						x.parentNode.insertBefore(s, x);
					};
					if (document.readyState==='complete') {
						l();
					} else if (w.attachEvent) {
						w.attachEvent('onload',l);
					} else {
						w.addEventListener('load',l,false);
					}
				}
			})();`,
		}));
	}
}
