import { Default, DTO, Label, Mapper, MetadataEntity, numberMapper } from '@bp/shared/models/metadata';
import { NonFunctionPropertyNames } from '@bp/shared/typings';

export type SubscriptionFeaturesLimitsKeys = NonFunctionPropertyNames<SubscriptionPlanFeaturesLimits>;

export class SubscriptionPlanFeaturesLimits extends MetadataEntity {

	@Mapper(numberMapper)
	@Default(null)
	transactions!: number | null;

	@Mapper(numberMapper)
	@Default(null)
	users!: number | null;

	@Mapper(numberMapper)
	@Default(null)
	paymentRouteRules!: number | null;

	@Mapper(numberMapper)
	@Default(null)
	checkouts!: number | null;

	@Mapper(numberMapper)
	@Default(null)
	routePsps!: number | null;

	@Label('PSPs')
	@Mapper(numberMapper)
	@Default(null)
	merchantPsps!: number | null;

	constructor(dto?: DTO<SubscriptionPlanFeaturesLimits>) {
		super(dto);
	}

}
